import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import LinesEllipsis from "react-lines-ellipsis"
import {
  downloadCardWrapper,
  outer,
  inner,
  footerButton,
} from "./download-card.module.css"

const DownloadCard = ({
  title,
  parentTitle,
  subtitle,
  image,
  link,
  description,
  footerDivider,
}) => {
  return (
    <Link to={link} className={`${downloadCardWrapper}`}>
      <article>
        <header className={`${outer} clearfix`}>
          <div className={`${inner}`}>
            {image && (
              <GatsbyImage image={image} alt={image.title ? image.title : ""} />
            )}
          </div>
        </header>
        {parentTitle && <span className={"category"}>{parentTitle}</span>}
        {<h1>{title}</h1>}
        {subtitle && <h2>{subtitle}</h2>}
        <p>
          {description && (
            <LinesEllipsis
              text={description}
              maxLine="5"
              ellipsis="..."
              basedOn="words"
            />
          )}
        </p>
        <div className={"gradient"}></div>

        <footer
          className={footerDivider ? `footer` : 'footer'}
        >
          <button className={footerButton}>Download</button>
        </footer>
      </article>
    </Link>
  )
}

export default DownloadCard
