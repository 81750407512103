import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import LinesEllipsis from "react-lines-ellipsis"
import {
  externalLinkCardWrapper,
  outer,
  inner,
  footerButton,
} from "./external-link-card.module.css"
import { isOwnDomain } from "../../modules/helpers"

const ExternalLinkCard = ({ title, subtitle, description, image, link }) => {
  return (
    // eslint-disable-next-line
    <a
      href={link}
      target={"_blank"}
      rel={isOwnDomain(link) ? "nofollow" : "noreferrer"}
      className={`${externalLinkCardWrapper}`}
    >
      <article>
        <header className={`${outer} clearfix`}>
          <div className={`${inner}`}>
            {image && (
              <GatsbyImage image={image} alt={image.title ? image.title : ""} />
            )}
          </div>
        </header>
        {<h1>{title}</h1>}
        {subtitle && <h2>{subtitle}</h2>}
        <p>
          <LinesEllipsis
            text={description}
            maxLine="5"
            ellipsis="..."
            basedOn="letters"
          />
        </p>
        <div className={"gradient"}></div>

        <footer className={"footer"}>
          <button className={footerButton}>Naar de website</button>
        </footer>
      </article>
    </a>
  )
}

export default ExternalLinkCard
