// extracted by mini-css-extract-plugin
export var articles = "outgrow-card-module--articles--4a7db";
export var category = "outgrow-card-module--category--4b643";
export var darkGradient = "outgrow-card-module--dark-gradient--bddc9";
export var darkStyle = "outgrow-card-module--dark-style--fcb8e";
export var deskArticlesGrid = "outgrow-card-module--desk-articles-grid--8eb77";
export var deskContent = "outgrow-card-module--desk-content--fbd64";
export var featured = "outgrow-card-module--featured--84cdc";
export var footer = "outgrow-card-module--footer--f464f";
export var footerButton = "outgrow-card-module--footer-button--cbc22";
export var footerDivider = "outgrow-card-module--footer-divider--ea38b";
export var gatsbyImageWrapper = "outgrow-card-module--gatsby-image-wrapper--4042e";
export var inner = "outgrow-card-module--inner--8351c";
export var outer = "outgrow-card-module--outer--af6ee";
export var readMoreText = "outgrow-card-module--read-more-text--b0466";
export var wideStyle = "outgrow-card-module--wide-style--37c4d";
export var wrapper = "outgrow-card-module--wrapper--263e2";