import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import get from "lodash/get"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import linkBuilder from "../../modules/linkBuilder"

/* Components */
import Layout from "../../components/layout"
import Seo from "../../components/seo/seo"
import SocialMeta from "../../components/social-meta/social-meta"
import StickyNav from "../../components/sticky-nav/sticky-nav"
import BackgroundImage from "gatsby-background-image"

//import Featured from '../../components/featured/featured'
import ArticleCard from "../../components/article-card/article-card"
import DownloadCard from "../../components/download-card/download-card"
import DeskCard from "../../components/desk-card/desk-card"
import ExternalLinkCard from "../../components/external-link-card/external-link-card"
import OutgrowCard from "../../components/outgrow-card/outgrow-card"
import Footer from "../../components/footer/footer"
import RichTextContent from "../../components/rich-text-content/rich-text-content"
import { dataLayer } from "../../modules/analytics"

/* Styles */
import {
  backgroundImage,
  campaignContent,
  sponsor,
  sponsorImageWrapper,
  sponsorTxt,
  sponsorButton,
  campaignContentInner,
  intro,
  campaignArticlesGrid,
} from "./campaign.module.css"
import MediaImage from "../../components/media-image/media-image"

class CampaignTemplate extends React.Component {
  render() {

    const campaign = get(this.props, "data.contentfulCampaign")
    const description = get(
      campaign,
      "description.childMarkdownRemark.rawMarkdownBody"
    )
    const desks = get(this.props, "pageContext.desks")
    const campaignUrl = `https://www.mkbservicedesk.nl${linkBuilder.campaign(
      campaign
    )}`
    const trackEvent = () => {
      dataLayer({
        event: "website-interaction",
        category: "sponsorbox",
        action: `external-link:${campaign.sponsor?.url}`,
        label: campaign.sponsor?.name,
      })
    }

    const image = getImage(campaign.image);
    const bgImage = convertToBgImage(image);

    return (
      <Layout location={this.props.location}>
        <Seo
          title={campaign.pageTitle || campaign.name}
          description={campaign.metaDescription || description}
          canonicalUrl={campaign.canonicalUrl}
          noIndex={campaign.noIndex}
        />
        <SocialMeta
          title={campaign.seoTitle || campaign.name}
          description={campaign.seoDescription || description}
          url={linkBuilder.campaign(campaign)}
          image={
            campaign.image && campaign.image.gatsbyImageData && campaign.image.gatsbyImageData.images.fallback.src
          }
        />
        <Helmet>
          <link rel="canonical" href={campaignUrl} />
        </Helmet>
        <header className="header">
          <StickyNav desks={desks} />
          <div className={backgroundImage}>
            {campaign.image && (
              <BackgroundImage
                className={backgroundImage}
                {...bgImage}
              ></BackgroundImage>
            )}
          </div>
        </header>
        <main className="main campaign">
          <div className="container">
            <section className={campaignContent}>
              {campaign.sponsor && (
                <a
                  id="sponsor"
                  href={campaign.sponsor.url}
                  rel="noreferrer"
                  target="_blank"
                  className={sponsor}
                  onClick={trackEvent()}
                >
                  <div className={sponsorImageWrapper}>
                    {campaign.sponsor.image && (
                      <MediaImage image={campaign.sponsor.image} alt={campaign.sponsor.name}  />
                    )}
                  </div>
                  <p className={sponsorTxt}>
                    {
                      campaign.sponsor.description.childMarkdownRemark
                        .rawMarkdownBody
                    }
                  </p>
                  <div className={sponsorButton}>Meer info</div>
                </a>
              )}

              <div className={campaignContentInner}>
                <h1>{campaign.name}</h1>
                {campaign.subtitle && <h2>{campaign.subtitle}</h2>}

                {description && <p className={intro}>{description}</p>}

                <div className="richtext">
                  {campaign.body && (
                    <RichTextContent
                      content={campaign.body}
                      addListMarker={true}
                      displayPagePath={linkBuilder.campaign(campaign)}
                    />
                  )}
                </div>

                <section className={campaignArticlesGrid}>
                  {campaign.relatedContent &&
                    campaign.relatedContent.map((content, index) => {
                      if (content.__typename === "ContentfulArticle")
                        return (
                          <ArticleCard
                            key={`content-${index}`}
                            title={content.title}
                            subtitle={content.subtitle}
                            image={content.image?.gatsbyImageData}
                            description={
                              content.description?.childMarkdownRemark
                                .rawMarkdownBody
                            }
                            link={linkBuilder.article(content)}
                            publishDate={content.publishDate}
                            updateDate={content.updateDate}
                            readMoreButton={true}
                          />
                        )
                      else if (content.__typename === "ContentfulDownload")
                        return (
                          <DownloadCard
                            key={`content-${index}`}
                            title={content.title}
                            subtitle={content.subtitle}
                            image={content.image?.gatsbyImageData}
                            description={
                              content.description?.childMarkdownRemark
                                .rawMarkdownBody
                            }
                            link={linkBuilder.download(content)}
                            publishDate={content.publishDate}
                            updateDate={content.updateDate}
                          />
                        )
                      else if (content.__typename === "ContentfulDesk")
                        return (
                          <DeskCard
                            key={`content-${index}`}
                            name={content.name}
                            subtitle={content.subtitle}
                            image={content.image?.gatsbyImageData}
                            description={
                              content.description?.childMarkdownRemark
                                .rawMarkdownBody
                            }
                            link={linkBuilder.desk(content)}
                          />
                        )
                      else if (content.__typename === "ContentfulExternalLink")
                        return (
                          <ExternalLinkCard
                            key={`content-${index}`}
                            title={content.title}
                            subtitle={content.subtitle}
                            image={content.image?.gatsbyImageData}
                            description={
                              content.description?.childMarkdownRemark
                                .rawMarkdownBody
                            }
                            link={content.url}
                          />
                        )
                      else if (content.__typename === "ContentfulOutgrow")
                        return (
                          <OutgrowCard
                            key={`content-${index}`}
                            title={content.title}
                            subtitle={content.subtitle}
                            image={content.image?.gatsbyImageData}
                            description={
                              content.description?.childMarkdownRemark
                                .rawMarkdownBody
                            }
                            outgrowSlug={content.outgrowSlug}
                            publishDate={content.publishDate}
                            updateDate={content.updateDate}
                            linkText={content.linkText}
                          />
                        )
                      else return <></>
                    })}
                </section>
              </div>
            </section>
          </div>
        </main>
        <Footer />
      </Layout>
    )
  }
}

export default CampaignTemplate

export const pageQuery = graphql`
  query CampaignBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }

    contentfulCampaign(slug: { eq: $slug }) {
      slug
      name
      subtitle
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, width: 1180, quality: 100)
      }
      body {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180, quality: 100)
          }
          ... on ContentfulArticle {
            contentful_id
            id
            legacyId
            title
            slug
            slug2
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
          ... on ContentfulDesk {
            contentful_id
            legacyId
            name
            slug
            slug2
            deskcategory {
              slug2
              desk {
                slug2
              }
            }
          }
          ... on ContentfulDeskCategory {
            contentful_id
            legacyId
            name
            slug
            slug2
            desk {
              slug2
              deskcategory {
                slug2
              }
            }
          }
          ... on ContentfulDownload {
            contentful_id
            id
            downloadType
            slug
            asset {
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
            formFields
            title
            sponsor {
              name
            }
            image {
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
          ... on ContentfulSponsor {
            contentful_id
            name
            url
          }
          ... on ContentfulTable {
            contentful_id
            tableData {
              id
              headerColumn
              headerRow
              tableData
            }
          }
          ... on ContentfulVideo {
            contentful_id
            url
          }
        }
      }
      sponsor {
        name
        url
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          title
          gatsbyImageData(layout: CONSTRAINED, width: 250, quality: 100)
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      relatedContent {
        __typename
        ... on ContentfulArticle {
          legacyId
          slug
          slug2
          categories {
            slug2
            desk {
              slug2
            }
          }
          title
          subtitle
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          image {
            gatsbyImageData(layout: CONSTRAINED, width: 1180, quality: 100)
          }
        }
        ... on ContentfulDownload {
          slug
          title
          subtitle
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          image {
            gatsbyImageData(layout: CONSTRAINED, width: 1180, quality: 100)
          }
          downloadType
        }
        ... on ContentfulDesk {
          legacyId
          name
          slug
          subtitle
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          image {
            gatsbyImageData(layout: CONSTRAINED, width: 1180, quality: 100)
          }
        }
        ... on ContentfulExternalLink {
          title
          subtitle
          showSidebarCtaImage
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          url
          image {
            gatsbyImageData(layout: CONSTRAINED, width: 1180, quality: 100)
          }
        }
        ... on ContentfulOutgrow {
          title
          subtitle
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          outgrowSlug
          image {
            gatsbyImageData(layout: CONSTRAINED, width: 1180, quality: 100)
          }
          linkText
        }
      }
      pageTitle
      metaDescription
      canonicalUrl
      noIndex
      socialTitle
      socialDescription
    }
  }
`
