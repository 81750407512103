// extracted by mini-css-extract-plugin
export var articles = "desk-card-module--articles--ca24b";
export var category = "desk-card-module--category--638c9";
export var darkGradient = "desk-card-module--dark-gradient--bebe4";
export var darkStyle = "desk-card-module--dark-style--013a3";
export var deskArticlesGrid = "desk-card-module--desk-articles-grid--7012f";
export var deskCardWrapper = "desk-card-module--desk-card-wrapper--314a3";
export var deskContent = "desk-card-module--desk-content--7c6fe";
export var featured = "desk-card-module--featured--1dfb5";
export var footer = "desk-card-module--footer--01283";
export var footerButton = "desk-card-module--footer-button--8b56d";
export var footerDivider = "desk-card-module--footer-divider--2d920";
export var gatsbyImageWrapper = "desk-card-module--gatsby-image-wrapper--70189";
export var inner = "desk-card-module--inner--f9a29";
export var outer = "desk-card-module--outer--c2f99";
export var readMoreText = "desk-card-module--read-more-text--878d9";
export var wideStyle = "desk-card-module--wide-style--6f7d6";