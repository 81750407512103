import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import LinesEllipsis from "react-lines-ellipsis"
import {
  wrapper,
  outer,
  inner,
  footerDivider as footerDividerClass,
  footer,
  footerButton,
} from "./outgrow-card.module.css"

const OutgrowCard = ({
  title,
  subtitle,
  image,
  outgrowSlug,
  description,
  linkText,
  footerDivider,
}) => {
  return (
    <a
      href={`https://mkbservicedesk.outgrow.us/${outgrowSlug}`}
      target="_blank"
      rel="noreferrer"
      className={`${wrapper}`}
    >
      <article>
        <header className={`${outer} clearfix`}>
          <div className={`${inner}`}>
            {image && (
              <GatsbyImage image={image} alt={image.title ? image.title : ""} />
            )}
          </div>
        </header>
        {<h1>{title}</h1>}
        {subtitle && <h2>{subtitle}</h2>}
        <p>
          {description && (
            <LinesEllipsis
              text={description}
              maxLine="5"
              ellipsis="..."
              basedOn="words"
            />
          )}
        </p>
        <div className={"gradient"}></div>

        <footer
          className={footerDivider ? `${footerDividerClass} ${footer}` : footer}
        >
          <button className={footerButton}>{linkText}</button>
        </footer>
      </article>
    </a>
  )
}

export default OutgrowCard
