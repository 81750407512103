import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import LinesEllipsis from "react-lines-ellipsis"
import {
  deskCardWrapper,
  outer,
  inner,
  footer,
  footerButton,
} from "./desk-card.module.css"

const DeskCard = ({ name, subtitle, description, image, link }) => {
  return (
    <Link to={link} className={`${deskCardWrapper}`}>
      <article>
        {<h1>{name}</h1>}
        <header className={`${outer} clearfix`}>
          <div className={`${inner}`}>
            {image && (
              <GatsbyImage image={image} alt={image.title ? image.title : ""} />
            )}
          </div>
        </header>
        {subtitle && <h2>{subtitle}</h2>}
        <p>
          <LinesEllipsis
            text={description}
            maxLine="6"
            ellipsis="..."
            basedOn="words"
          />
        </p>
        <div className={"gradient"}></div>

        <footer className={footer}>
          <button className={footerButton}>Naar de desk</button>
        </footer>
      </article>
    </Link>
  )
}

export default DeskCard
